import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Bread from "../../components/bread";
import Footer from "../../components/footer";
import HeaderComponent from "../../components/header";
import config from "./../../config/host";
import VisaSvg from "./../../images/icons/visa.svg";

export default function Donate(props) {
  const { register, setValue } = useForm();
  const { history } = props;
  const [amount, setAmount] = useState(0);
  const [method, setMethod] = useState(1);
  const [usd, setUsd] = useState(3430);
  const [show, setShow] = useState(false);
  // const bank = "tdb";
  const bank = "khanbank";
  let initialized = false;

  const { api } = config;
  const formRef = useRef(null);
  const initWallet = async () => {
    axios
      .get("https://monxansh.appspot.com/xansh.json?currency=USD")
      .then(({ data }) => setUsd(data[0]?.rate_float ?? 3430))
      .catch(() => {});
  };
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      initWallet();
      if (!props.location.search) {
        setAmount(0);
      } else {
        if (props.location.search.split("=")[0] != "?id") {
          var num =
            parseInt(parseInt(props.location.search.split("=")[1])) || 0;
          setAmount(num);
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title:
              "Thank you for your donation. Let the nine white wishes come true.",
            showConfirmButton: false,
            timer: 5000,
          });
          // var id = parseInt(parseInt(props.location.search.split('=')[1])) || 0;
          // axios.get(`${api}reqs?id=${id}`)
          // .then(({data}) => {
          //   if(data.status === '200') {
          //     if(!data.success) {
          //       Swal.fire({
          //         icon: 'error',
          //         title: 'Oops...',
          //         text: data.message,
          //         confirmButtonText: "Close",
          //       })
          //       setTimeout(()=>{
          //         history.push('/donate-now')
          //       },5000)
          //     } else {
          //       return Swal.fire({
          //         position: 'center',
          //         icon: 'success',
          //         title: 'Thank you for your donation. Let the nine white wishes come true.',
          //         showConfirmButton: false,
          //         timer: 5000,
          //       })
          //     }
          //   } else {
          //     history.push('/donate-now')
          //   }
          // })
          // .catch(() => {})
        }
      }
      setMethod(1);
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleOpen = () => {
    if (amount > 0) {
      setShow(true);
    }
  };
  const getdonate = async () => {
    handleClose();
    if (amount > 0) {
      let sum = bank === "tdb" ? amount : usd * amount;
      axios
        .post(`${api}${bank === "tdb" ? "tdb" : "khanbank"}`, { amount: sum })
        .then(({ data }) => {
          if (data.status === 200) {
            if (bank === "tdb") {
              setValue("OrderID", data.data.id);
              setValue("Description", data.data.id);
              if (bank === "tdb") formRef.current.submit();
            } else if (bank === "khanbank") {
              if ((data?.data ?? null) != null) {
                window.location.href =
                  JSON.parse(data.data)?.formUrl ??
                  "https://cancerfund.mn/donate-now";
              } else {
                return Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: "Try again after a while?",
                  confirmButtonText: "Close",
                });
              }
            }
          } else {
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: "Try again after a while?",
              confirmButtonText: "Close",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          console.log("Error response:", e.response?.data);
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Network Error",
            footer: "Please wait and try again",
            confirmButtonText: "Close",
          });
        });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: 32 }}
          >
            Disclaimer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: "justify" }}>
            Please note that donations made to the Cancer Support "Munkh"
            Foundation are considered non-refundable gifts from the heart. Once
            a donation is made, it cannot be returned. By making a donation to
            our foundation, you are giving a gift of support to those in need,
            and your donation will be used to provide critical resources and
            assistance to cancer patients in Mongolia.
          </p>
          <p style={{ textAlign: "justify" }}>
            We greatly appreciate your support and generosity, and we want to
            assure you that every donation we receive is used to make a real
            difference in the lives of those affected by cancer. Your donation
            will be put to use to help those in need, and it cannot be refunded
            or returned.
          </p>
          <p style={{ textAlign: "justify" }}>
            Thank you for considering a donation to the Cancer Support "Munkh"
            Foundation. Your contribution can have a meaningful impact on the
            lives of cancer patients and their families in Mongolia, and we are
            grateful for your support.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={getdonate}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <HeaderComponent currentPage="donate" />
      <Bread currentPage="Donation" image="breadcrumbs_bg1.jpg" />
      <main id="body-content">
        <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <h1 className="heading-main">
                  <small>
                    Together, we can make a difference in the lives of cancer
                    patients in Mongolia. By donating to the Cancer Support
                    "Munkh" Foundation, you can help provide critical resources
                    and support to those who need it most.
                  </small>
                </h1>
                <p style={{ textAlign: "justify" }}>
                  Cancer is a devastating disease that affects millions of
                  people around the world, including in Mongolia. Many cancer
                  patients in Mongolia face significant financial and logistical
                  challenges when it comes to accessing the care and treatment
                  they need. The Cancer Support "Munkh" Foundation is dedicated
                  to addressing these challenges and providing meaningful
                  support to cancer patients and their families.
                  <br />
                  <br />
                  Your donation can help us provide a range of essential
                  services, from financial assistance to help patients cover the
                  cost of treatment and medications, to emotional support and
                  counseling to help patients and their families cope with the
                  challenges of a cancer diagnosis. Additionally, your donation
                  will help fund our efforts to improve access to cancer care
                  and treatment in Mongolia, including our work to purchase
                  state-of-the-art equipment and resources for oncology centers
                  across the country.
                  <br />
                  <br />
                  No donation is too small to make a difference. Whether you
                  choose to make a one-time gift or become a recurring donor,
                  your contribution can have a meaningful impact on the lives of
                  those affected by cancer in Mongolia. By donating to the
                  Cancer Support "Munkh" Foundation, you are helping to ensure
                  that no one faces cancer alone.
                  <br />
                  <br />
                  <i>
                    Thank you for your generosity and support. Together, we can
                    make a real difference in the fight against cancer.
                  </i>
                </p>
                <div className="donation-wrap">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 20,
                    }}
                  >
                    <h3 className="h3-sm fw-5 txt-blue mb-3">
                      Account information
                    </h3>
                    <div style={{ fontSize: 14 }}>
                      <img
                        src={require("./../../images/icons/currency.png")}
                        alt="Cancerfund"
                        style={{ width: "60px" }}
                      />
                      EURO
                    </div>
                  </div>
                  <div className="row">
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Swift code:
                      </li>
                      <li style={{ float: "left", fontSize: 14, marginTop: 2 }}>
                        {bank === "tdb" ? "TDBMMNUB" : "AGMOMNUB"}
                      </li>
                    </ul>
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Bank:
                      </li>
                      <li style={{ float: "left", fontSize: 14, marginTop: 2 }}>
                        {bank === "tdb"
                          ? "TRADE AND DEVELOPMENT BANK OF MONGOLIA"
                          : "KHAN BANK"}
                      </li>
                    </ul>
                    <div className="col-md-12">
                      <hr />
                    </div>
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Bank address:
                      </li>
                      <li style={{ float: "left", fontSize: 14, marginTop: 2 }}>
                        {bank === "tdb"
                          ? "Sukhbaatar district 14210, Peace avenue 19"
                          : "Chinggis Avenue-6, Stadium Orgil-1, Khan-Uul District, 15 khoroo, Ulaanbaatar 17010"}
                      </li>
                    </ul>
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Iban:
                      </li>
                      <li
                        style={{
                          float: "left",
                          fontSize: 14,
                          marginTop: 2,
                          marginRight: 12,
                        }}
                      >
                        {bank === "tdb"
                          ? "MN320004000460089783"
                          : "MN140005005163847716"}
                      </li>
                      <li style={{ float: "left", fontSize: 14 }}>
                        <div
                          style={{
                            border: "1px solid #eee",
                            padding: "2px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              bank === "tdb"
                                ? "MN320004000460089783"
                                : "MN140005005163847716"
                            );
                            alert("Copied IBAN");
                          }}
                        >
                          Copy
                        </div>
                      </li>
                    </ul>
                    <div className="col-md-12">
                      <hr />
                    </div>
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Account name:
                      </li>
                      <li style={{ float: "left", fontSize: 14, marginTop: 2 }}>
                        CANCER SUPPORT MUNKH FOUNDATION
                      </li>
                    </ul>
                    <ul
                      className="col-md-6"
                      style={{ listStyleType: "none", marginBottom: 0 }}
                    >
                      <li
                        style={{
                          float: "left",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginRight: 12,
                        }}
                      >
                        Account number:
                      </li>
                      <li
                        style={{
                          float: "left",
                          fontSize: 14,
                          marginTop: 2,
                          marginRight: 12,
                        }}
                      >
                        {bank === "tdb" ? "460089783" : "5163847716"}
                      </li>
                      <li style={{ float: "left", fontSize: 14 }}>
                        <div
                          style={{
                            border: "1px solid #eee",
                            padding: "2px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              bank === "tdb" ? "460089783" : "5163847716"
                            );
                            alert("Copied Account number");
                          }}
                        >
                          Copy
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="donation-wrap">
                  <h3 className="h3-sm fw-5 txt-blue mb-3">
                    Select Your Donation Amount
                  </h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline1"
                            name="customRadioInline1"
                            className="custom-control-input"
                            onClick={() => setAmount(10)}
                            checked={amount === 10 ? true : false}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline1"
                          >
                            $10
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline2"
                            name="customRadioInline1"
                            className="custom-control-input"
                            onClick={() => setAmount(50)}
                            checked={amount === 50 ? true : false}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline2"
                          >
                            $50
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline3"
                            name="customRadioInline1"
                            className="custom-control-input"
                            onClick={() => setAmount(100)}
                            checked={amount === 100 ? true : false}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline3"
                          >
                            $100
                          </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline4"
                            name="customRadioInline1"
                            className="custom-control-input"
                            onClick={() => setAmount(200)}
                            checked={amount === 200 ? true : false}
                          />
                          <label
                            className="custom-control-label"
                            for="customRadioInline4"
                          >
                            $200
                          </label>
                        </div>
                        <div className="mt-3">
                          <input
                            type="text"
                            value={amount}
                            onChange={(enter) => {
                              if (
                                enter.target.value &&
                                enter.target.value != null &&
                                enter.target.value !== ""
                              ) {
                                setAmount(parseInt(enter.target.value));
                              } else {
                                setAmount(0);
                              }
                            }}
                            className="form-control"
                            id="custom"
                            placeholder="Custom Amount"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <li
                          style={{ display: "inline" }}
                          onClick={() => setMethod(1)}
                        >
                          <img
                            src={VisaSvg}
                            alt="cancerfund.mn"
                            style={{
                              width: 140,
                              backgroundColor: "#fff",
                              marginRight: 20,
                              border: `1px solid ${
                                method === 1 ? "#0057a0" : "#eee"
                              }`,
                              paddingLeft: 20,
                              paddingRight: 20,
                              cursor: "pointer",
                            }}
                          />
                        </li>
                        {/* <li style={{ display: 'inline' }} onClick={() => setMethod(2)}><img src={GSvg} alt="cancerfund.mn" style={{ width: 140, backgroundColor: '#fff', marginRight: 20, border: `1px solid ${method === 2 ? '#0057a0' : '#eee'}`, paddingLeft: 20, paddingRight: 20, cursor: 'pointer' }}/></li>
                        <li style={{ display: 'inline' }} onClick={() => setMethod(3)}><img src={ASvg} alt="cancerfund.mn" style={{ width: 140, backgroundColor: '#fff', marginRight: 20, border: `1px solid ${method === 3 ? '#0057a0' : '#eee'}`, paddingLeft: 20, paddingRight: 20, cursor: 'pointer' }}/></li> */}
                      </ul>
                      <div className="border-top mb-4"></div>
                    </div>
                    {method === 1 ? (
                      <>
                        {/* <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="Card Number">Card Number</label>
                            <input type="text" className="form-control" id="name" placeholder="**** **** **** ****"/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="Expiration">Expiration</label>
                            <input type="text" className="form-control" id="last_name" placeholder="MM / YY"/>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="Verification">Verification</label>
                            <input type="email" className="form-control" id="email" placeholder="CVC"/>
                          </div>
                        </div> */}
                        <div className="col-md-12 mt-3">
                          <button
                            className="btn btn-default"
                            onClick={handleOpen}
                          >
                            <i data-feather="heart"></i> Donate Now
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="Connection will be made soon">
                            {method === 2
                              ? '"GOOGLE PAY" '
                              : method === 3 && '"APPLE PAY" '}{" "}
                            Connection will be made soon
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="faqs-sidebar pos-rel">
                  <div className="bg-overlay blue opacity-80"></div>
                  <form>
                    <h3 className="h3-sm fw-7 txt-white mb-3">Disclaimer</h3>
                    <hr />
                    <p style={{ color: "#fff" }}>
                      Please note that donations made to the Cancer Support
                      "Munkh" Foundation are considered non-refundable gifts
                      from the heart. Once a donation is made, it cannot be
                      returned. By making a donation to our foundation, you are
                      giving a gift of support to those in need, and your
                      donation will be used to provide critical resources and
                      assistance to cancer patients in Mongolia.
                      <br />
                      <br />
                      We greatly appreciate your support and generosity, and we
                      want to assure you that every donation we receive is used
                      to make a real difference in the lives of those affected
                      by cancer. Your donation will be put to use to help those
                      in need, and it cannot be refunded or returned.
                      <br />
                      <br />
                      Thank you for considering a donation to the Cancer Support
                      "Munkh" Foundation. Your contribution can have a
                      meaningful impact on the lives of cancer patients and
                      their families in Mongolia, and we are grateful for your
                      support.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Test address
      https://www.bankcard.mn:9710/shopindex.jsp
      https://202.131.226.85:6330/shopindex.jsp
      https://backend.cancerfund.mn/api/paymentapprove
      https://backend.cancerfund.mn/api/paymentreject
      */}
      {bank === "tdb" && (
        <form
          ref={formRef}
          action="https://www.bankcard.mn:9710/shopindex.jsp"
          method="POST"
          onsubmit="doSubmit();"
        >
          <input
            type="hidden"
            name="ReturnURLApprove"
            value={`${api}paymentapprove`}
          />
          <input
            type="hidden"
            name="ReturnURLDecline"
            value={`${api}paymentreject`}
          />
          <input type="hidden" name="PurchaseAmount" value={amount * 100} />
          <input type="hidden" name="merid" value="OMEC0014" />
          <input type="hidden" name="Currency" value="840" />
          <input type="hidden" name="OrderID" {...register("OrderID")} />
          <input
            type="hidden"
            name="OrderDescription"
            {...register("Description")}
          />
          <input type="hidden" name="Address" value="Ulaanbaatar, Mongolia" />
          <input type="hidden" name="SenderPostalCode" value="455000" />
          <input type="hidden" name="ResidentCountry" value="496" />
          <input type="hidden" name="ResidentCityInLatin" value="ULAANBAATAR" />
          <input type="hidden" name="SenderName" value="CANCERFUND" />
          <input type="hidden" name="phone" value="" />
          <input type="hidden" name="email" value="" />
          <input type="hidden" name="NextStep" value="AREQ" />
          <input type="hidden" name="Operation" value="Purchase" />
          <input type="hidden" />
        </form>
      )}
      <Footer />
    </>
  );
}
